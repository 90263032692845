<template>
     <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav" :class="{ 'navbar-shrink': !view.topOfPage}">
            <div class="container px-4 px-lg-5">
                <a class="navbar-brand" href="/"><img class="logo" src="./assets/images/logo.png" alt="BPCOne" /></a>
                <button class="navbar-toggler navbar-toggler-right" type="button" @click="showmenu = !showmenu"><span class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navbarResponsive" v-bind:class="[showmenu ? 'activemenu' : 'inactivemenu']">
                    <ul class="navbar-nav ms-auto my-2 my-lg-0">
                        <li class="nav-item"><router-link class="nav-link" to="about" @click="showmenu = !showmenu">About Us</router-link></li>
                        <li class="nav-item"><router-link class="nav-link" to="business" @click="showmenu = !showmenu">Business Vertical</router-link></li>
                        <li class="nav-item"><router-link class="nav-link" to="contact" @click="showmenu = !showmenu">Contact Us</router-link></li>
                    </ul>       
                </div>
            </div>
      </nav>
    <div class="overlay" v-show="showmenu"></div>
    <transition @before-enter="scrollTop" mode="out-in" appear>
      <router-view/>
    </transition>   
    <footer class="bg-gray  py-3">
       <div class="container px-4 px-lg-5"><div class="small text-center text-muted">Copyright &copy; 2024 - BPCOne</div></div>
    </footer>
</template>

<style lang="scss">
 @import 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css';
 @import 'https://cdnjs.cloudflare.com/ajax/libs/SimpleLightbox/2.1.0/simpleLightbox.min.css';
 @import 'https://fonts.googleapis.com/css2?family=Ms+Madi&family=Oswald:wght@200;300;400;500;700&display=swap'
</style>

<script>  
  // import './assets/js/scripts.js';
  export default{
  data:() => ({
    showmenu:false,
      view: {
        topOfPage: true
      }
    }),

beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },

methods: {
    handleScroll(){
      if(window.pageYOffset>0){
        if(this.view.topOfPage) this.view.topOfPage = false
      } else {
        if(!this.view.topOfPage) this.view.topOfPage = true
      }
    }
 }
}
</script>
