import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import BusinessView from '@/views/BusinessView.vue'
import ContactView from '@/views/ContactView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Welcome to BPCOne'}
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { title: 'About Us'}
  },
  {
    path: '/business',
    name: 'business',
    component: BusinessView,
    meta: { title: 'Business Vertical'}
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: { title: 'Contact Us'}
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) =>{
  document.title = 'Welcome to BPCOne';
  next();
});

export default router
