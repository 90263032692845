<template>
  <div class="about">
     
    <div class="innerbanner aboutus"></div>
    <section class="page-section bg-white pos_rltv" id="about">
        <div class="container px-4 px-lg-5">
            <div class="content_left halfpart">
                <h3 class="largeheading">We make it together</h3>
                 <p>BPC, as the name speaks for itself, <strong>Basic Progressive Concept</strong> are the three fold objectives of this organization. Our belief in progressivism focuses on social progress that includes belief in mankind’s ability to improve the environment and conditions of life on the basic concept of ‘building better communities with us’.</p>
                </div>
               <div class="content_right">                   
                   <p>With over 19 years of a strong, customer focused approach and a continuous quest for world class quality, BPCOne has unmatched capabilities across Technology, Engineering, Construction and maintains a leadership in all its major lines of business. We can proudly say BPCOne is a truly diverse company in the most literal sense. Our sense of pride lies not in individual footprint, but in collective stride!</p>
               </div>
               <div class="dividerborder"></div>
               <div class="mid_container">
                <div class="row gx-4 mt-5 aboutbpc">
                       <div class="col-lg-5 col-md-5">
                  <img src="../assets/images/mission.gif" alt=""/>
                </div> 
                <div class="col-lg-7 col-md-7 px-1">
                   <h3>Our Mission</h3>
                   <p>BPC India committedly provides high quality, knowledgeable, professional services to enhance communities where our clients live and work. We aim to dedicate our labor specially to make social & economic improvement and modernize the underdeveloped infrastructure in remote, backward areas of India through our contribution in the field of highway, railway, traffic & transportation engineering. Our performance combines exceptional design, innovative solutions and proven skills. Our people-focused approach to planning and design connects & empowers communities, improves lives and achieves better social outcomes by opening up economic opportunities and widening access to education, training & employment.</p>
                 </div>  
                    <hr/>

               <div class="col-lg-5 col-md-6 mt-3">
                     <img src="../assets/images/business-vision.gif" alt=""/>
                </div>
                 <div class="col-lg-7 col-md-7 px-1 mt-3">
                    <h3>Our Vision</h3>
                    <p>BPC aims to integrate itself to provide multidisciplinary engineering consultancy services across the world. BPC aspires to be the pioneer to show how to turn the company beyond ‘Business as usual’ into the ‘Next big thing’ which is ultimately about creating the unexpected. BPC envisions its role in nation building and nurtures the dream to push in. With an eagle vision of making humanitarian contributions to society, BPC wants to spread its wings wide across new frontiers and swears to avenue high to success with every project.</p>
                 </div>  

                    <hr/>    
                 
                <div class="col-lg-5 col-md-5 mt-3">
                     <img src="../assets/images/clients.gif" alt=""/>
                </div>
                <div class="col-lg-7 col-md-7 px-1 mt-3">
                   <h3>Our Strengths</h3>
                   <p>Rising from the grassroots level, a journey of a thousand miles that began with a single step, has today a global footprint. This has been possible only because we understand the changes occurring in our industry and update our business accordingly to outweigh the risks – especially in today’s ever-evolving technological landscape.  Passion for learning, attention to details, team work & time management skills, creativity, analytical thinking and problem solving are our strong weapons to conquer the world.</p>
                 </div>
                 <hr/>
                <div class="col-lg-5 col-md-5 mt-3">
                       <img src="../assets/images/values.gif" alt=""/>
                  </div>
                   <div class="col-lg-7 col-md-7 px-1 mt-3">
                   <h3>Our Values</h3>
                   <p>Client – Our aim is to develop enduring business relationships built on trust & integrity by delivering excellent professional quality services on time, at reasonable and affordable prices.<br/><br/>Employees – Our employees are the fabric of our company. They determine our reputation, capability and ultimately our success. We provide in-house world class facilities that include a modern workplace, auditorium, library, training center, employee's welfare activities, regular training and skills upgrades which encourage them for self-improvement, teamwork and innovation with a high level of technical competence and attention to details.</p>
                 </div>                
                </div>
            </div>
        </div>
    </section>

    <div class="clearfix"></div>
    <section class="aboutgrouppic">
        <ul class="m-0 px-0">
            <li class="col-md-3 px-1"><img src="../assets/images/business-9.webp" alt="" /></li>
            <li class="col-md-3 px-1"><img src="../assets/images/business-2.webp" alt="" /></li>
            <li class="col-md-3 px-1"><img src="../assets/images/business-3.webp" alt="" /></li>
            <li class="col-md-3 px-1"><img src="../assets/images/business-4.webp" alt="" /></li>
            <li class="col-md-3 px-1"><img src="../assets/images/business-5.webp" alt="" /></li>
            <li class="col-md-3 px-1"><img src="../assets/images/business-6.webp" alt="" /></li>
            <li class="col-md-3 px-1"><img src="../assets/images/business-7.webp" alt="" /></li>
            <li class="col-md-3 px-1"><img src="../assets/images/business-8.webp" alt="" /></li>
        </ul>
    </section>

  <div class="clearfix"></div>
    <section class="page-section pos_rltv bg-dark" id="philosophy">
        <div class="skewobj2"></div>
        <div class="container px-4 px-lg-5">       
            <a class="playbutton" @click="modalpopup"><i class="bi bi-play-btn-fill"></i></a>  
             <div class="content_right_small whitetext mt-6 philosophycontent">    
                 <h5 class="arttext">Philosophy</h5>               
                   <p>BPC Organization was not any inherited property with strong back up. It was started from scratch by a few hardworking, aspiring entrepreneurs who believed in ‘Quality, not quantity’, and dared to dream big. An engineering consultancy firm, that was yesterday just a fledgling organization, small in size but big in vision, today has grown to become a truly global powerhouse only following this philosophy of life that explains - 'For those in pursuit of excellence, no terrain is too tough, no sea too deep and no sky too high! They either will find a way, or will make one!'</p>
               </div>
               <div class="clearfix"></div>
        </div>

         <div id="myModal" class="modalpopup" v-show="modalshow"><span class="close" @click="closepopup">&times;</span>
          <div class="modalpop-content">
            <iframe width="100%" src="https://www.youtube.com/embed/2sDZ5fvfdFM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>

</div>
    </section>

     <section class="page-section pos_rltv" id="team">
         <h5>TEAM</h5>
         <div class="row gx-4 gx-lg-5">
             <div class="col-lg-4 col-md-4 founder_bg"></div>
              <div class="col-lg-8 col-md-8 founder_details">
                  <div class="founder_img">
                     <div class="profileimg"><img src="../assets/images/team/ksg.webp" alt=""/></div>
                      <h2>Kalyan S Gayen</h2>
                      <p>Founder</p>
                     <div class="socialmedia">
                        <a href="https://www.linkedin.com/in/ksgayen/" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
                      </div>
                  </div>

                  <div class="founder_content">
                    <p>The man with a disctint thought, to create employability for dynamic youth of the Nation,made him the Serial Entrepreneur & Founder of BPCOne. He shares his rich Professional experience of more than 19 Years as a Technocrat for infrastructural projects.The essense of his knowledge enriches the team for making the organisation better.His love for Music, art & sports always encourages all sort of cultural activities in the organisation.</p>
                  </div>
              </div>
         </div>
       <div class="container px-4 px-lg-5 pt-5">
          <h2 class="text-center mt-0">Co-Founders & Leaders</h2>
             <hr class="divider" />        
                <div class="row gx-3 gx-lg-5 centerbox">
                    <div class="col-lg-4 col-md-6 text-center">
                        <div class="mt-5 teamcontent">
                            <div class="teamimage"><img src ="../assets/images/team/tusharsubhra-de.webp" alt=""/></div>
                            <h4 class="h4 mb-2">Tusharsubhra De</h4>
                             
                            <div class="socialmedia">
                                <hr class="divider-thin mt-1" />
                                <a href="https://www.linkedin.com/in/tusharsubhrade/" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
                            </div>
                        </div>
                    </div>

                      <div class="col-lg-4 col-md-6 text-center">
                        <div class="mt-5 teamcontent">
                            <div class="teamimage"><img src ="../assets/images/team/palash-shamantra.jpg" alt=""/></div>
                            <h4 class="h4 mb-2">Palash Ch. Samanta</h4>                           
                            <div class="socialmedia">
                                <hr class="divider-thin mt-1" />
                                <a href="https://www.linkedin.com/in/palash-samanta-b50711186/" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                      <div class="col-lg-4 col-md-6 text-center">
                        <div class="mt-5 teamcontent">
                            <div class="teamimage"><img src ="../assets/images/team/jayanta-dhara.webp" alt=""/></div>
                            <h4 class="h4 mb-2">Jayanta Kr. Dhara</h4>
                           
                            <div class="socialmedia">
                                <hr class="divider-thin mt-1" />
                                <a href="https://www.linkedin.com/in/jkdhara/" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
                            </div>
                        </div>
                    </div>

                   <div class="col-lg-4 col-md-6 text-center">
                        <div class="mt-5 teamcontent">
                            <div class="teamimage"><img src ="../assets/images/team/atanu-santra.jpg" alt=""/></div>
                            <h4 class="h4 mb-2">Atanu Santra</h4>
                            
                            <div class="socialmedia">
                                <hr class="divider-thin mt-1" />
                                <a href="https://www.linkedin.com/in/atanu-santra-339861b1/" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
                            </div>
                        </div>
                    </div>     

                     <div class="col-lg-4 col-md-6 text-center">
                        <div class="mt-5 teamcontent">
                            <div class="teamimage"><img src ="../assets/images/team/biplab-ghorui.jpg" alt=""/></div>
                            <h4 class="h4 mb-2">Biplab Ghorui</h4>
                            
                            <div class="socialmedia">
                                <hr class="divider-thin mt-1" />
                                <a href="https://www.linkedin.com/in/biplab-ghorui-194b6a187/" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
                            </div>
                        </div>
                    </div>    
                     
                </div>
            </div>
        </section>

         <!-- Call to action-->
        <section class="page-section bg-dark text-white">
            <div class="container px-4 px-lg-5 text-center">
              <h2 class="mb-4 whitetext">Start your project with us</h2>  
             <router-link  class="btn btn-light btn-xl circlebtn" to="contact">Contact us <i class="bi bi-arrow-right"></i></router-link >  
           </div>
        </section>
        
 
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
// export default class AboutView extends Vue {};

export default {    
     data:() => ({   
          modalshow:false,
      }),

     methods:
      {        
         modalpopup(){
            this.modalshow = true
         },

         closepopup(){
            this.modalshow = false
         }
      }
}
</script>