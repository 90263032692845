import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/css/styles.css'
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';

import { Client } from 'appwrite';


createApp(App).use(router).mount('#app')


const app = createApp(App);
app.component('scroll-parallax', ScrollParallax);

const client = new Client();

client
    .setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('65cef309524918fb9133');